<template>
  <div class="page">
    <div class="login-form">
      <div style="text-align:center;font-weight: bold;font-size: 18px;">销售系统</div>
      <el-form style="margin-top: 20px;" label-width="60px">
        <el-form-item label="用户名">
          <el-input v-model="form.username" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="form.password" auto-complete="on" @keyup.enter.native="confirmForm" />
        </el-form-item>

        <el-form-item style="text-align: right;">
          <el-checkbox v-model="form.remember">记住账号</el-checkbox>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button @click="confirmForm" type="primary" style="width:100%;">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash';
export default {
  data() {
    return {
      form: {
      },
    }
  },

  mounted() {

    const username = localStorage.getItem('username');
    if(username) {
      this.form = { username, password: '123456', remember: true }
    }
  },

  methods: {
    async confirmForm() {
      try {
        const res = await this.$api.execute('account.login', this.form)
        this.$store.commit('setAccountInfo', res);
        this.$success('登录成功');

        if(this.form.remember) {
          localStorage.setItem('username', this.form.username)
        } else {
          localStorage.removeItem('username')
        }
        
        this.$router.push(this.$route.query.redirect || '/').catch(() => {})
        this.$emit('success')
        this.visible = false;
      } catch(e) {
        this.$error(e.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-image: url('../../assets/login-background.jpg');
  height: 100vh;
  overflow: hidden;

  background-size: cover;
  background-position: 50% 50%;

  .login-form {
    background: white;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border-radius: 5px;
    box-shadow: 5px 5px #6666;
    
    width: 300px;

  }
}
</style>